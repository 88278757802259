import styled from 'styled-components';

import { Box } from '@/shared/ui/box';
import { Icon as UIIcon } from '@/shared/ui/icon';
import { Text } from '@/shared/ui/text';
export const Wrapper = styled(Box)`
	background-color: ${({ theme: { modal } }) => modal.background};
	border: 2px solid ${({ theme: { modal } }) => modal.border};
	border-top: 4px solid ${({ theme: { modal } }) => modal.border};

	@media ${({ theme: { media } }) => media.mobile} {
		width: 100%;
		position: fixed;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: ${({ theme: { zIndex } }) => zIndex.modal};
		justify-content: space-between;
	}
`;

export const Content = styled(Box)`
	height: 160px;

	@media ${({ theme: { media } }) => media.mobile} {
		height: auto;
	}
`;

export const Icon = styled(UIIcon)`
	cursor: pointer;
	fill: ${({ theme: { modal } }) => modal.icon};
	transition: ${({ theme: { transition } }) => transition.primary};

	&:hover {
		opacity: 0.7;
	}
`;

export const Description = styled(Text)`
	word-break: break-word;

	@media ${({ theme: { media } }) => media.mobile} {
		width: auto;
	}
`;
