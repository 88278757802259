import { memo } from 'react';

import * as Styled from './stepper.styles';
import { StepperProps } from './stepper.types';

const StepperComponent = ({ steps, currentStep, ...props }: StepperProps) => {
	const currentIndex = steps.indexOf(currentStep);
	const previousSteps = steps.slice(0, currentIndex);

	return (
		<Styled.StepperWrapper count={steps.length}>
			{steps.map((step) => (
				<Styled.Stepper
					key={step}
					weight={600}
					size="s"
					label={step}
					disabled={currentStep !== step && !previousSteps.includes(step)}
					{...props}
				/>
			))}
		</Styled.StepperWrapper>
	);
};

export default memo(StepperComponent);
