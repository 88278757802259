import closeSelect from '@/shared/assets/icons/ui-kit/close-select-light.png';
import openSelect from '@/shared/assets/icons/ui-kit/open-select-light.png';

import * as RADIUS from '../../styles/radiuses';
import * as COLOR from './colors';
import { yachtTheme as theme } from './theme';

export const yachtDarkTheme = {
	colors: {
		primary: COLOR.ORANGE_1,
		secondary: COLOR.WHITE,
		tertiary: COLOR.GRAY_1,
		body: COLOR.DARK_2,
		metaColor: COLOR.DARK_2,
		...theme.colors,
	},
	input: {
		primary: {
			border: COLOR.GRAY_1,
			focus: COLOR.ORANGE_1,
			fontColor: COLOR.GRAY_6,
			placeholderColor: COLOR.DARK_2,
			background: COLOR.DARK_6,
			radius: RADIUS.PX_2,
		},
		secondary: {
			border: COLOR.GRAY_1,
			focus: COLOR.ORANGE_1,
			fontColor: COLOR.GRAY_6,
			placeholderColor: COLOR.GRAY_6,
			background: COLOR.DARK_3,
			radius: RADIUS.PX_6,
		},
		...theme.input,
	},
	textarea: {
		primary: {
			fontColor: COLOR.GRAY_6,
			placeholderColor: COLOR.GRAY_6,
			background: COLOR.DARK_6,
			focus: COLOR.ORANGE_1,
			border: COLOR.GRAY_1,
			radius: RADIUS.PX_2,
		},
		secondary: {
			fontColor: COLOR.GRAY_6,
			placeholderColor: COLOR.GRAY_6,
			background: COLOR.DARK_6,
			focus: COLOR.ORANGE_1,
			border: COLOR.GRAY_1,
			radius: RADIUS.PX_6,
		},
		...theme.textarea,
	},
	box: {
		border: COLOR.GRAY_1,
		background: COLOR.DARK_6,
	},
	checkbox: {
		background: COLOR.ORANGE_1,
		border: COLOR.ORANGE_1,
	},
	logo: {
		fill: COLOR.DARK_3,
		stroke: COLOR.GRAY_1,
	},
	page: {
		...theme.page,
		wrapper: {
			background: COLOR.DARK_4,
			border: `1px solid ${COLOR.GRAY_1}`,
		},
	},
	text: {
		size: {
			...theme.text.size,
		},
		colors: {
			primary: COLOR.WHITE,
			secondary: COLOR.GRAY_10,
			tertiary: COLOR.ORANGE_1,
			quaternary: COLOR.ORANGE_1,
			quinary: COLOR.GRAY_2,
			invertedPrimary: COLOR.GRAY_11,
			invertedSecondary: COLOR.ORANGE_1,
			invertedTertiary: COLOR.WHITE,
			invertedQuaternary: COLOR.GRAY_10,
			inherit: 'inherit',
			...theme.colors,
		},
	},
	chip: {
		background: COLOR.DARK_3,
		borderColor: COLOR.ORANGE_1,
	},
	button: {
		...theme.button,
		error: {
			background: COLOR.ERROR,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				opacity: 0.85,
			},

			'&:active': {
				opacity: 1,
			},

			'&:disabled': {
				opacity: 0.7,
				cursor: 'initial',
			},
		},
		contained: {
			background: COLOR.ORANGE_1,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				background: COLOR.ORANGE_2,
			},

			'&:active': {
				background: COLOR.ORANGE_1,
			},

			'&:disabled': {
				background: COLOR.ORANGE_5,
				cursor: 'initial',
			},
		},
		containedInverted: {
			background: COLOR.ORANGE_1,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				background: COLOR.ORANGE_2,
			},

			'&:active': {
				background: COLOR.ORANGE_1,
			},
		},
		outlined: {
			background: 'transparent',
			color: COLOR.ORANGE_1,
			border: `1px solid ${COLOR.ORANGE_1}`,

			'&:hover': {
				background: COLOR.ORANGE_9,
			},

			'&:active': {
				background: COLOR.ORANGE_1,
			},

			'&:disabled': {
				border: `1px solid ${COLOR.ORANGE_5}`,
				['pointer-events']: 'none',
				color: COLOR.ORANGE_5,
			},
		},
		outlinedInverted: {
			background: 'transparent',
			color: COLOR.WHITE,
			border: `1px solid ${COLOR.WHITE}`,

			'&:hover': {
				background: COLOR.ORANGE_2,
			},

			'&:active': {
				background: COLOR.ORANGE_1,
			},
		},
		containedPrimary: {
			background: COLOR.ORANGE_1,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				background: COLOR.ORANGE_2,
			},

			'&:active': {
				background: COLOR.ORANGE_2,
			},
		},
		containedSecondary: {
			background: COLOR.BLUE_1,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				background: COLOR.BLUE_2,
			},

			'&:active': {
				background: COLOR.BLUE_1,
			},

			'&:disabled': {
				background: COLOR.BLUE_5,
				cursor: 'initial',
			},
		},
	},
	title: {
		colors: {
			primary: COLOR.WHITE,
			secondary: COLOR.DARK_1,
		},
		...theme.title,
	},
	toggle: {
		background: COLOR.GRAY_1,
		fill: COLOR.ORANGE_1,
		moon: COLOR.WHITE,
		sun: COLOR.GRAY_10,
	},
	link: {
		color: COLOR.GRAY_8,
		active: {
			background: COLOR.ORANGE_1,
			color: COLOR.WHITE,
		},
	},
	avatar: {
		...theme.avatar,
	},
	layouts: {
		header: {
			...theme.layouts.header,
			background: COLOR.DARK_4,
			borderBottom: `1px solid ${COLOR.GRAY_1}`,
			icon: {
				primary: COLOR.WHITE,
			},
		},
		sidebar: {
			...theme.layouts.sidebar,
			background: COLOR.DARK_4,
			borderRight: `1px solid ${COLOR.GRAY_1}`,
			borderBottom: `1px solid ${COLOR.GRAY_1}`,
			bottomIcon: {
				fill: COLOR.DARK_4,
				border: COLOR.GRAY_11,
				path: COLOR.GRAY_11,
			},
			toggle: {
				fill: COLOR.DARK_4,
				stroke: COLOR.GRAY_1,
			},
		},
	},
	isDark: true,
	menu: {
		background: COLOR.DARK_6,
		hover: COLOR.DARK_5,
		border: `1px solid ${COLOR.GRAY_1}`,
	},
	stepper: {
		colors: {
			primary: COLOR.ORANGE_1,
			disabled: COLOR.GRAY_7,
		},
		border: {
			primary: COLOR.ORANGE_1,
			disabled: COLOR.GRAY_1,
		},
	},
	autocomplete: {
		...theme.autocomplete,
	},
	datepicker: {
		color: {
			primary: COLOR.WHITE,
			scheme: 'dark',
		},
		hover: COLOR.DARK_5,
		...theme.datepicker,
	},
	leaflet: {
		color: {
			primary: COLOR.WHITE,
		},
	},
	option: {
		background: COLOR.DARK_6,
		hover: COLOR.DARK_5,
		border: `1px solid ${COLOR.GRAY_1}`,
		color: COLOR.GRAY_8,
	},
	select: {
		arrow: {
			open: openSelect,
			close: closeSelect,
		},
	},
	zIndex: {
		...theme.zIndex,
	},
	tabs: {
		color: COLOR.GRAY_7,
		activeColor: COLOR.ORANGE_1,
		background: COLOR.DARK_2,
		border: `1px solid ${COLOR.GRAY_1}`,
		...theme.tabs,
	},
	table: {
		headColor: COLOR.WHITE,
		measurementBackground: COLOR.DARK_6,
		bodyColor: COLOR.GRAY_10,
		background: COLOR.DARK_6,
		headBackground: COLOR.DARK_5,
		border: `1px solid ${COLOR.GRAY_1}`,
		offlineBackground: COLOR.GRAY_1,
		...theme.table,
	},
	toast: {
		background: COLOR.DARK_5,
		closeColor: COLOR.WHITE,
	},
	caption: {
		primary: COLOR.WHITE,
		secondary: COLOR.GRAY_9,
		tertiary: COLOR.WHITE,
		quaternary: COLOR.GRAY_10,
		gray: COLOR.GRAY_11,
		dark: COLOR.GRAY_5,
	},
	tag: {
		border: COLOR.ORANGE_1,
		radius: RADIUS.PX_5,
		background: 'transparent',
		color: COLOR.ORANGE_1,
		...theme.tag,
	},
	list: {
		color: COLOR.WHITE,
	},
	container: {
		border: COLOR.GRAY_1,
		radius: RADIUS.PX_2,
	},
	section: {
		border: COLOR.GRAY_1,
		color: COLOR.GRAY_10,
	},
	breadcrumbs: {
		color: COLOR.ORANGE_1,
		background: 'transparent',
		border: `1px solid ${COLOR.ORANGE_1}`,
		radius: RADIUS.PX_2,
	},
	transition: {
		...theme.transition,
	},
	modal: {
		border: COLOR.ORANGE_1,
		borderInside: COLOR.GRAY_1,
		background: COLOR.DARK_3,
		backgroundHeader: COLOR.DARK_5,
		icon: COLOR.WHITE,
		color: COLOR.WHITE,
	},
	pagination: {
		color: COLOR.GRAY_8,
		background: 'transparent',
		border: `1px solid ${COLOR.GRAY_1}`,
		active: {
			background: COLOR.ORANGE_1,
			color: COLOR.WHITE,
		},
	},
	widgets: {
		circle: { color: COLOR.ORANGE_2, background: COLOR.ORANGE_10 },
		arrow: COLOR.WHITE,
		addSection: {
			background: COLOR.DARK_5,
		},
	},
	tooltip: {
		background: COLOR.DARK_4,
		hover: COLOR.DARK_5,
		border: `1px solid ${COLOR.GRAY_1}`,
		color: COLOR.ORANGE_1,
	},
	breakpoints: {
		...theme.breakpoints,
	},
	media: {
		...theme.media,
	},
	radio: {
		color: COLOR.GRAY_8,
		active: COLOR.ORANGE_1,
	},
	spinner: {
		...theme.spinner,
		color: {
			primary: COLOR.ORANGE_1,
			secondary: COLOR.WHITE,
		},
	},
	map: {
		icon: COLOR.WHITE,
	},
	windy: {
		icon: {
			background: COLOR.ORANGE_1,
			fill: {
				active: COLOR.DARK_1,
				unactive: COLOR.WHITE,
			},
			...theme.windy.icon,
		},
		buttons: {
			background: COLOR.GRAY_12,
			...theme.windy.buttons,
		},
	},
	dashboard: {
		icon: {
			expand: COLOR.ORANGE_1,
		},
	},
	notification: {
		icon: {
			backgroundColor: COLOR.ORANGE_10,
			activeColor: COLOR.ORANGE_1,
		},
		card: {
			activeBackground: 'none',
			defaultBorder: COLOR.DARK_6,
			border: COLOR.ORANGE_1,
		},
		menu: {
			icon: COLOR.WHITE,
		},
	},
	icon: {
		roundWrapper: {
			primary: {
				backgroundColor: COLOR.ORANGE_10,
				fill: COLOR.ORANGE_1,
			},
			secondary: {
				backgroundColor: COLOR.GRAY_10,
				fill: COLOR.GRAY_5,
			},
		},
	},
	skeleton: {
		gradient: `linear-gradient(to right, ${COLOR.ORANGE_1} 0%, ${COLOR.ORANGE_2} 50%, ${COLOR.ORANGE_3} 100%)`,
		shadow: COLOR.ORANGE_5,
	},
	marker: {
		primary: COLOR.BLUE_1,
		secondary: COLOR.GRAY_2,
		error: COLOR.ERROR,
	},
};
