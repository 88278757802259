import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Router } from '@/app/providers/router';
import * as Styled from '@/app/styles/app-layouts';
import { useUser } from '@/entities/user';
import { useUserInitQuery } from '@/entities/user/api/user.api';
import { useAgreementsModal } from '@/features/agreements';
import { AppRoutes } from '@/shared/constants/app-routes.constant';
import { useMatchMedia } from '@/shared/lib/hooks/use-match-media.hook';
import { Header } from '@/widgets/header';
import { Sidebar } from '@/widgets/sidebar';

const HtmlContentComponent = () => {
	const isTablet = useMatchMedia('tabletAndMobile');
	const { acceptedLatestAgreements } = useUser();
	const { refetch } = useUserInitQuery(null);
	const [openConditionsAndPrivacyPolicyModal, close] = useAgreementsModal();
	const { pathname } = useLocation();

	useLayoutEffect(() => {
		if (pathname === AppRoutes.TermsOfService || pathname === AppRoutes.Privacy) {
			return;
		} else if (!acceptedLatestAgreements) {
			close();

			openConditionsAndPrivacyPolicyModal();
		}
	}, [pathname, acceptedLatestAgreements]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			refetch();
		}, 3 * 60 * 60 * 1000);

		return () => clearInterval(intervalId);
	}, [refetch]);

	return (
		<>
			{!isTablet && <Sidebar />}
			<Styled.ContentWrapper>
				<Header />
				<Router />
			</Styled.ContentWrapper>
		</>
	);
};

export default HtmlContentComponent;
