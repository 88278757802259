import styled from 'styled-components';

import { Text } from '../text';

export const StepperWrapper = styled('div')<{ count: number }>`
	display: grid;
	grid-template-columns: ${({ count }) => `repeat(${count}, 1fr)`};
	gap: 4px;
`;

export const Stepper = styled(Text)<{ disabled?: boolean }>`
	padding: 8px 24px;
	display: flex;
	justify-content: center;
	border-bottom: ${({
		theme: {
			stepper: { border },
		},
		disabled,
	}) => `2px solid ${disabled ? border.disabled : border.primary}`};
	color: ${({
		theme: {
			stepper: { colors },
		},
		disabled,
	}) => (disabled ? colors.disabled : colors.primary)};
`;
