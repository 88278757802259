import styled from 'styled-components';

export const CustomLink = styled('div')`
	font-weight: 600;
	color: ${({ theme: { text } }) => `${text.colors.tertiary} !important`};
	font-size: 15px;
	transition: ${({ theme: { transition } }) => transition.primary};
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
`;
