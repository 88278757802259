import styled from 'styled-components';

import { flexGap } from '@/shared/lib/helpers/flex-gag-polyfill.helper';

import { Box } from '../box';
import { Icon } from '../icon';
import { CheckboxProps } from './checkbox.types';

export const Wrapper = styled(Box)<Pick<CheckboxProps, 'disabled' | 'alignItems'>>`
	cursor: pointer;
	${flexGap('12px')};
	width: fit-content;
	align-items: ${({ alignItems }) => alignItems};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const Input = styled(Box)<Pick<CheckboxProps, 'checked' | 'width' | 'height'>>`
	display: inline-block;
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	background: ${({
		checked,
		theme: {
			checkbox: { background },
		},
	}) => checked && background};
	border-radius: 4px;
	border: ${({
		checked,
		theme: {
			checkbox: { border },
		},
	}) => `1px solid ${!checked && border}`};
	transition: all 150ms;
`;

export const InputContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`;

export const HiddenInput = styled.input`
	position: absolute;
	cursor: pointer;
	height: ${({ height }) => height};
	width: ${({ width }) => width};
	opacity: 0;
	z-index: 10;
`;

export const CheckIcon = styled(Icon)`
	position: absolute;
	z-index: 1;
	top: 4px;
	left: 3px;
`;
