import { memo } from 'react';

import { Box } from '@/shared/ui/box';
import { Checkbox } from '@/shared/ui/checkbox';
import { Text } from '@/shared/ui/text';

import { CustomLink } from './checkbox-list.styles';
import { CheckboxListComponentProps } from './checkbox-list.types';

const CheckboxListComponent = memo(({ checkboxes, checkedState, onChange }: CheckboxListComponentProps) => {
	const handleClick = (link: string) => () => {
		window.open(link, '_blank');
	};

	return checkboxes.map((checkbox, index) => (
		<Box key={index} gap={6}>
			<Checkbox alignItems="start" checked={checkedState[index]} onChange={() => onChange(index)} />
			<Box gap={4}>
				{typeof checkbox.text === 'string' && <Text label={checkbox.text} />}
				{typeof checkbox.text !== 'string' && checkbox.text}
				{checkbox.linkText && checkbox.link && (
					<CustomLink onClick={handleClick(checkbox.link)}>{checkbox.linkText}</CustomLink>
				)}
			</Box>
		</Box>
	));
});

export default CheckboxListComponent;
