import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonModal, createModalHook, ModalOptions } from '@/entities/modal';
import { useToastActions } from '@/entities/toast';
import { useUser } from '@/entities/user';
import { useMatchMedia } from '@/shared/lib/hooks/use-match-media.hook';
import { Box } from '@/shared/ui/box';
import { Stepper } from '@/shared/ui/stepper';
import { Text } from '@/shared/ui/text';

import { useUpdateUserAgreementsMutation } from '../../api/agreements.api';
import { STEPS, Steps } from '../../model/utils/agreements.util';
import { CheckboxList } from '../checkbox-list';
import { ScrollableBox } from './agreements.styles';

const AgreementsModelComponent = memo(({ onClose }: ModalOptions) => {
	const { t } = useTranslation(['agreementsSettings', 'common']);
	const { addToast } = useToastActions();
	const {
		latestAcceptedTermsAndConditionsUrl: termsAndConditionsFile,
		latestPrivacyPolicyUrl: privacyPolicyFile,
		isAcceptedLatestTermsAndConditions,
		isAcceptedLatestPrivacyPolicy,
		agreementsAcceptedTime,
	} = useUser();
	const steps = STEPS({ termsAndConditionsFile, privacyPolicyFile });
	const isMobile = useMatchMedia('mobile');
	const isLaptop = useMatchMedia('tabletAndLaptop');
	const [updateUserAgreements, { isLoading }] = useUpdateUserAgreementsMutation();
	const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
	const [isFullscreen, setFullscreen] = useState(false);

	const isFirstAcceptance = !agreementsAcceptedTime;

	const initialCheckboxState = steps.reduce((acc, step) => {
		if (step.title === Steps.TermsAndConditions) {
			acc[step.title] = [isAcceptedLatestTermsAndConditions, isAcceptedLatestTermsAndConditions];
		} else if (step.title === Steps.PrivacyPolicy) {
			acc[step.title] = [isAcceptedLatestPrivacyPolicy];
		} else {
			acc[step.title] = step.checkboxes ? step.checkboxes.map(() => false) : [];
		}
		return acc;
	}, {} as { [key: string]: boolean[] });

	const [checkboxState, setCheckboxState] = useState(initialCheckboxState);

	const isAllTrue = (array: boolean[]) => array.every((element) => element === true);

	const handleFullscreenChange = () => {
		if (document.fullscreenElement) {
			setFullscreen(true);
		} else {
			setFullscreen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullscreenChange);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
		};
	}, []);

	useEffect(() => {
		const savedStepIndex = localStorage.getItem('agreementsCurrentStep');
		const savedCheckboxState = localStorage.getItem('agreementsCheckboxState');

		if (savedStepIndex !== null) {
			setCurrentStepIndex(Number(savedStepIndex));
		}

		if (savedCheckboxState) {
			setCheckboxState(JSON.parse(savedCheckboxState));
		}
	}, []);

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent) => {
			if (event.key === 'agreementsCheckboxState') {
				const newState = event.newValue ? JSON.parse(event.newValue) : initialCheckboxState;
				setCheckboxState(newState);
			}

			if (event.key === 'agreementsCurrentStep') {
				const newStepIndex = event.newValue ? Number(event.newValue) : 0;
				setCurrentStepIndex(newStepIndex);
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [initialCheckboxState]);

	const nextStep = () => {
		if (currentStepIndex < steps.length - 1) {
			const newStepIndex = currentStepIndex + 1;

			setCurrentStepIndex(newStepIndex);

			localStorage.setItem('agreementsCurrentStep', newStepIndex.toString());
		} else {
			updateUserAgreements({
				acceptedTermsAndConditionsVersion: isAllTrue(checkboxState[Steps.TermsAndConditions]),
				acceptedPrivacyPolicyVersion: isAllTrue(checkboxState[Steps.PrivacyPolicy]),
				dataSharing: isAllTrue(checkboxState[Steps.DataSharing]),
			}).then(() => {
				localStorage.removeItem('agreementsCurrentStep');
				localStorage.removeItem('agreementsCheckboxState');

				onClose();

				addToast({
					type: 'success',
					title: t('common:toast.success.title'),
					message: t('agreementsSettings:toast.success.update.terms.and.conditions.message'),
				});
			});
		}
	};

	const handleCheckboxChange = useCallback((stepTitle: string, checkboxIndex: number) => {
		setCheckboxState((prevState) => {
			const updatedStepState = [...prevState[stepTitle]];
			updatedStepState[checkboxIndex] = !updatedStepState[checkboxIndex];

			const newState = {
				...prevState,
				[stepTitle]: updatedStepState,
			};

			localStorage.setItem('agreementsCheckboxState', JSON.stringify(newState));

			return newState;
		});
	}, []);

	const currentStep = steps[currentStepIndex];

	useEffect(() => {
		const panels = Array.from(document.getElementsByClassName('panel'));
		const lastPanel = Array.from(document.getElementsByClassName('panel'))[panels.length - 1] as
			| HTMLElement
			| undefined;

		if (lastPanel) {
			lastPanel.style.width = isLaptop ? '90%' : '60%';
		}
	}, [isLaptop]);

	return (
		<CommonModal
			title={
				isFirstAcceptance
					? t('agreementsSettings:modal.first.acceptance.agreement.title')
					: t('agreementsSettings:modal.agreement.title')
			}
			onClose={onClose}
			allowClose={false}
			fullscreen={!isMobile}
			onConfirm={nextStep}
			okText="Confirm"
			width="100%"
			isLoading={isLoading}
			disabled={
				currentStep.title === Steps.TermsAndConditions
					? !isAllTrue(checkboxState[Steps.TermsAndConditions])
					: !(isAllTrue(checkboxState[Steps.TermsAndConditions]) && isAllTrue(checkboxState[Steps.PrivacyPolicy]))
			}
		>
			<Box direction="column" gap={24} width="100%">
				<Stepper steps={steps.map((step) => step.title)} currentStep={currentStep.title} />
				<Box direction="column" gap={8}>
					<Text
						color="secondary"
						weight={600}
						size="s"
						label={isFirstAcceptance ? currentStep.caption : currentStep?.lastAcceptedCaption || currentStep.caption}
					/>
					<Text
						size="xs"
						color="quinary"
						label={isFirstAcceptance ? currentStep.text : currentStep?.lastAcceptedText || currentStep.text}
					/>
				</Box>
				<ScrollableBox direction="column" gap={12} fullscreen={isFullscreen} isMobile={isMobile}>
					{currentStep.file && <iframe src={`${currentStep.file}#view=FitH&zoom=100`} />}
					<CheckboxList
						checkboxes={currentStep.checkboxes}
						checkedState={checkboxState[currentStep.title]}
						onChange={(index) => handleCheckboxChange(currentStep.title, index)}
					/>
				</ScrollableBox>
			</Box>
		</CommonModal>
	);
});

export default createModalHook<ModalOptions>((props) => () => <AgreementsModelComponent {...props} />);
